@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900|Poppins:300,400,500,500i,600,600i,700,800&display=swap');
@import url("https://fonts.googleapis.com/css?family=Montserrat%3A200%2C200i%2C300%2C300i%2C400%2C400i%2C500%2C500i%2C600%2C600i%2C700%2C700i%2C800%2C800i%2C900%2C900i%7CPrata%3A200%2C200i%2C300%2C300i%2C400%2C400i%2C500%2C500i%2C600%2C600i%2C700%2C700i%2C800%2C800i%2C900%2C900i%7CAmatic%20SC%3A200%2C200i%2C300%2C300i%2C400%2C400i%2C500%2C500i%2C600%2C600i%2C700%2C700i%2C800%2C800i%2C900%2C900i%7CRoboto%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CRoboto%20Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CAmatic%20SC%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CPrata%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CMontserrat%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&display=swap");
@font-face {
    font-family: font-custom;
    src: url(../fonts/font-custom.eot);
    src: url(../fonts/font-custom.eot?#iefix) format("embedded-opentype"), url(../fonts/font-custom.woff) format("woff"), url(../fonts/font-custom.ttf) format("truetype"), url(../fonts/font-custom.svg#font-custom) format("svg");
    font-weight: 400;
    font-style: normal;
}
div.apie_admin {
    background: url(../apie_img/bg.jpg) no-repeat top center fixed;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}
div.apie_admin ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
div.apie_admin ::-webkit-scrollbar-track {
    background: #f1f1f1;
}
div.apie_admin ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}
div.apie_admin ::-webkit-scrollbar-thumb:hover {
    background: #555;
}
div.apie_admin .apie_modal-title {
    font-size: 14px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.apie_menu-left {
    margin: 6px !important;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
}
body.swal2-shown.swal2-height-auto {
    height: inherit !important;
}
div.apie_admin .mat-progress-bar {
    position: fixed;
    top: 65px;
    width: calc(100% - 175px);
}
.apie_menu-left .apie_nav-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 25px;
    color: #fff;
    list-style: none outside none;
    padding: 10px 10px 10px 18px !important;
    text-decoration: none;
    text-transform: capitalize;
    border-radius: 4px;
}
.apie_menu-left .apie_nav-link.active_page,
.apie_menu-left .apie_nav-link:hover {
    background-color: rgb(218 215 214 / 20%);
    color: #fff !important;
    text-shadow: none;
    border-left: 2px solid rgb(255 255 255 / 20%);
    padding-left: 16px !important;
    position: relative;
}
.apie_menu-left .apie_nav-link.active_page:after,
.apie_menu-left .apie_nav-link:hover:after {
    border-bottom: 6px solid rgb(255 255 255 / 20%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: 19px;
    left: -6px;
    content: "";
    display: block;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    tranform: rotate(90deg);
}
.apie_dt-top {
    color: #444;
    padding: 0 15px;
    display: flex;
    height: 50px;
    padding-right: 0;
    margin-left: auto !important;
}
.apie_dt-top ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
}
.apie_dt-top ul li {
    font-size: 14px;
    display: inline;
    padding-right: 15px;
    align-items: center;
}
.apie_dt-top ul li + li {
    padding-left: 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: rgb(255 255 255 / 10%) -1px 0 0;
}
.apie_dt-top ul li i {
    margin-right: 5px;
}
.apie_dt-top ul li span {
    font-weight: 500 !important;
    color: #444 !important;
}
.apie_logoutBtn {
    width: 40px;
    text-align: center;
    cursor: pointer;
}
.apie_logoutBtn:hover {
    background: none 0 0 repeat scroll rgba(0, 0, 0, 0.2);
}
.admin__main {
    position: relative;
    top: 70px;
    left: 185px;
    width: calc(100% - 195px);
    height: calc(100% - 80px);
    padding: 0 0 20px;
    background: #fff;
    border-radius: 20px;
}
.apie_logo img {
    height: 45px;
}
.admin__main h1 {
    font-weight: lighter;
    font-size: 3em;
}
.font-custom {
    font-family: font-custom;
}
.font-custom {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.apie_modal-header {
    height: 60px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
}
.icon-bitrix:before {
    content: "\b24";
}
:focus {
    outline: 0;
}
a {
    font-family: Poppins, sans-serif;
    border: none;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    -webkit-transition: 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: 0s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-transition-property: background-color, color;
    transition-property: background-color, color;
}
ul li {
    font-weight: 400;
}
html .admin {
    transition: transform 0.3s;
}
html .admin.toolBarVisible {
    transform: translate3d(45px, 0, 0);
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
#apie_preloader {
    z-index: 9999999999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
nav.apie_navbar a {
    text-transform: inherit;
}
.custom-modal button:focus,
.custom-modal-right button:focus {
    outline: 0;
}
.apie_navbar-collapse:after,
.apie_navbar-collapse:before,
.apie_navbar:after,
.apie_navbar:before {
    content: " ";
    display: table;
}
.apie_navbar-brand {
    font-weight: 700 !important;
    margin-left: 0;
    letter-spacing: 0.1em;
}
.apie_navbar-collapse {
    padding-right: 15px;
    color: #121212;
}
.apie_navbar .fa,
.apie_navbar .fas,
.custom-modal .fa,
.custom-modal .fas,
.custom-modal-right .fa,
.custom-modal-right .fas {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}
.profile-timeline .profile-box {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    margin-bottom: 16px;
}
.profile-timeline .profile-box header {
    padding: 6px 16px;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
}
.profile-timeline .accent {
    background-color: #039be5 !important;
    color: #fff !important;
}
.profile-timeline .accent .mat-icon {
    color: #fff;
}
.profile-timeline .profile-box header .title {
    font-size: 17px;
}
.profile-timeline .profile-box header .more {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7) !important;
}
.profile-timeline .profile-box header .more .mat-icon-button {
    color: rgba(255, 255, 255, 0.7) !important;
}
.profile-timeline .profile-box .apie_content {
    padding: 0 16px;
}
#profile .apie_content {
    flex: 1;
}
.profile-timeline .latest-activity .apie_content {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
}
.profile-timeline .latest-activity .apie_content .activities {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}
.profile-timeline .latest-activity .apie_content .activities .activity {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
    border-radius: 18px;
    padding: 10px;
    margin-bottom: 15px;
    min-height: auto;
}
.profile-timeline .latest-activity .apie_content .activities .activity .type {
    font-size: 12px;
    font-weight: 500;
    color: #343a40;
}
.profile-timeline .latest-activity .apie_content .activities .activity .username {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
    display: block;
}
.mat-icon-button {
    background: 0 0;
}
.modalbodayWrap {
    font-size: 14px;
}
.custom-modal .modal-dialog,
.custom-modal-right .modal-dialog {
    margin: 0;
}
.custom-modal .modal-dialog-scrollable,
.custom-modal-right .modal-dialog-scrollable {
    max-height: 100%;
}
.custom-modal .modal-content,
.custom-modal-right .modal-content {
    border-radius: 0;
    border: 0;
    height: 100%;
    background: 0 0;
}
.custom-modal .modal-dialog-scrollable .modal-content,
.custom-modal-right .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: visible;
    margin: 15px 0 15px 15px;
}
.notBitrix .mat-form-field + .mat-form-field {
    margin-left: 8px;
}
.fnfLabel {
    max-width: 100%;
    padding: 6px 12px;
    border-radius: 0 0 18px 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    /* line-height: 2; */
    color: #fff;
    background: rgb(43 42 42);
    z-index: -1;
}
.urlInput {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.appenfields {
    display: flex;
}
.appenfields .mat-form-field {
    width: 110px;
}
.pushlblRight label {
    text-align: right;
}
.pushlblRight .mat-form-field {
    width: 100px;
}
.pushlblRight input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.profile-sidebar {
    background: #343a40;
}
.custom-modal-right.modal .modal-dialog {
    direction: ltr;
}
.custom-modal-right.modal.fade {
    direction: rtl;
}
.custom-modal .apie_modal-body,
.custom-modal-right .apie_modal-body {
    padding: 0;
    padding-top: 0;
}
.custom-modal .modal-content ::-webkit-scrollbar,
.custom-modal-right .modal-content ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.custom-modal .modal-content ::-webkit-scrollbar-track,
.custom-modal-right .modal-content ::-webkit-scrollbar-track {
    background: #e1e6ec;
}
.custom-modal .modal-content ::-webkit-scrollbar-thumb,
.custom-modal-right .modal-content ::-webkit-scrollbar-thumb {
    background: #888;
}
.custom-modal .modal-content ::-webkit-scrollbar-thumb:hover,
.custom-modal-right .modal-content ::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.modal.custom-modal-right.fade .modal-dialog,
.modal.custom-modal.fade .modal-dialog {
    opacity: 1;
    top: inherit;
    overflow: visible;
}
.custom-modal .apie_modal-header,
.custom-modal-right .apie_modal-header {
    background: 0 0;
    color: #fff;
    background: #edf1f4;
    border-radius: 0;
    padding: 0 1rem;
    min-height: 60px;
    border-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 1;
}
.no-items {
    text-align: center;
    margin-top: 40vh;
}
.material-icons {
    font-size: 18px;
}
.custom-modal .apie_modal-title,
.custom-modal-right .apie_modal-title {
    font-size: 14px;
    color: #273142;
    margin-bottom: 0 !important;
    font-weight: 600;
    font-family: Montserrat !important;
}
.custom-modal .apie_modal-title button {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mat-flat-button.fixedBtn {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    color: #fff;
    width: 100%;
    border-radius: 0 !important;
    border: 0 !important;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 2 !important;
    background: #343a40;
}
input[type="checkbox"],
input[type="radio"] {
    border: 0 !important;
    height: 1px !important;
    position: absolute !important;
    width: 1px !important;
}
.custon-btn {
    background: #343a40 !important;
}
.custon-btn:hover {
    background: #272829 !important;
}
.fixedBtn:hover {
    background: #272829;
}
.matBtnDelete {
    color: red;
}
.matBtnEdit {
    color: green;
}
.matAddBtn {
    background: #3bc16b !important;
    color: #fff !important;
}
.matRemoveBtn {
    background: #e46f1d !important;
    color: #fff !important;
}
.matBtnCopy {
    font-size: 14px !important;
}
.mat-progress-bar-buffer {
    background-color: #aee2c0;
}
.mat-progress-bar-fill::after {
    background-color: #3bc16b;
}
.loaderOvrField {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 1.25em;
    background: rgba(255, 255, 255, 0.65);
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
    border-radius: 18px;
    overflow: hidden;
    align-items: flex-end;
}
.loaderOvrField > * {
    width: 100%;
}
.loaderOvrField .mat-progress-bar {
    height: 2px;
}
.block .info {
    border-bottom: 0 !important;
    background: rgba(232, 232, 232, 0.66);
    line-height: 40px;
}
.thumb-cover-img {
    width: 50px;
    height: 50px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 12px;
}
.thumb-cover-img img {
    max-width: initial;
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    height: 100%;
}
.cname_scode {
    float: left;
}
.leftFlex {
    width: 60%;
}
.example-full-width {
    width: 100%;
}
.mat-form-field-underline {
    display: block !important;
}
.scode-wrap {
    padding-top: 10px;
    width: 100px;
}
.pushlblRight .mat-input-element:disabled,
.pushlblRight .matBtnCopy,
.scode-wrap .mat-input-element:disabled,
.scode-wrap .matBtnCopy {
    font-size: 10px !important;
}
.pushlblRight .mat-input-element:disabled,
.scode-wrap .mat-input-element:disabled {
    background: #e2e2e2;
    line-height: 1.6;
    padding-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.pushlblRight .mat-form-field-wrapper,
.scode-wrap .mat-form-field-wrapper {
    padding-bottom: 10px;
}
.img_edit_modal {
    position: relative;
    cursor: pointer;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    background: #fff;
}

.img_edit_modal::before{
    content: '';
    position: absolute;
    top: 0;
    height: 60px;
    width: 100%;
    background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgba(0,0,0,1) 100%);
}

.img_edit_modal img{
    height: 200px;
    object-fit: cover;
    width: 100%;
}

.img_edit_modal .imgedit-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #4caf50;
    background: rgb(255 255 255);
    font-size: 16px;
    height: 30px;
    line-height: 10px!important;
    overflow: hidden;
    width: 30px;
}
.img_edit_modal:hover .imgedit-icon {
}
.img_select .mat-checkbox-inner-container,
.img_select .mat-radio-container {
    position: absolute;
    opacity: 0;
}
.img_select .layoutlist {
    text-align: center;
    width: 50%;
    margin: 0 auto;
}
.img_select .mat-radio-label-content {
    padding: 0;
}
.img_select.mat-checkbox-checked .layoutlist,
.img_select.mat-radio-checked .layoutlist {
    /* border: 1px solid #000; */
}
.hide_radio_check .mat-radio-container {
    position: absolute;
    opacity: 0;
}
.hide_radio_check .mat-radio-label-content {
    padding-left: 0;
}
.hide_radio_check .mat-radio-label {
    text-align: center;
    background: #cfd8dc;
    height: 110px;
    border-radius: 18px;
}
.hide_radio_check .mat-radio-checked .mat-radio-label,
.hide_radio_check .mat-radio-label:hover {
    background: #343a40;
}
.hide_radio_check .mat-radio-label-content {
    width: 100%;
}
.hide_radio_check .interface {
    padding: 10px 0;
}
.hide_radio_check .mat-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
    color: #fff;
    margin-bottom: 5px;
}
.hide_radio_check p {
    color: #fff;
    margin-bottom: 0 !important;
}
.block {
    border: 1px solid rgba(232, 232, 232, 0.66);
}

.libSqImg{
    width: 130px;
    /* height: 100px; */
    object-fit: cover;
    transition: transform 200ms;
  }
.apie_libWrap {
    width: 100%;
    height: 100%;
}

.apie_libWrap:before{
    content: '';
    position: absolute;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0;
    transition: 300ms opacity;
}

.apie_libWrap:hover:before{
    opacity: 0.2;
}

.apie_libWrap .file_ext{
    opacity: 0;
    position: absolute;
    right: 2px;
    top: 2px;
    background: linear-gradient(180deg, rgb(22 177 255) 0%, rgb(2 206 157) 100%);
    color: #fff;
    padding: 5px 10px;
    font-size: 10px;
    letter-spacing: 1px;
    font-weight: 700;
    cursor: auto;
    border-radius: 10px;
    transform: scale(0);
    transition: 300ms transform;
}

.apie_libWrap:hover .file_ext{
    opacity: 1;
    transform: scale(0.8);
}

.apie_libWrap .available_sizes{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 18px 0 0 18px;
    /* background: #fff; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    transition: 300ms transform;
    transform: translateX(-100%);
    z-index: 1;
}

.apie_libWrap .available_sizes .mat-radio-button span.size{
    background: #fff;
    margin: 3px 0;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 10px;
    font-size: 10px;
    color: #000;
    display: block;
    /* box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 21%);  */
}

.apie_libWrap .available_sizes .mat-radio-button.mat-radio-checked span.size{
    background: linear-gradient(180deg, rgb(22 177 255) 0%, rgb(2 206 157) 100%);
    color: #fff;
}


.apie_libWrap .available_sizes .mat-radio-button{
    transform: translateX(-100%);
    transition: 300ms transform;
}

.apie_libWrap:hover .available_sizes .mat-radio-button{
    transform: none;
}

.apie_libWrap:hover .available_sizes{
    transform: none;
}

.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(1){
    transition-delay: 50ms;    
}

.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(2){
    transition-delay: 0.1s;    
}

.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(3){
    transition-delay: 0.15s;    
}
.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(4){
    transition-delay: 0.2s;    
}
.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(5){
    transition-delay: 0.25s;    
}
.apie_libWrap:hover .available_sizes > .mat-radio-button:nth-child(6){
    transition-delay: 0.3s;    
}

.apie_libWrap .toCopy {
    position: absolute;
    right: 0;
    opacity: 0;
    transition: 300ms all;
}
.apie_libWrap:hover .toCopy {
    opacity: 1;
}
.apie_libWrap .img_select label {
    height: 100%;
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 18px;
    border: 1px solid rgb(222 226 230 / 50%);
    justify-content: center;
    align-items: center;
    /* white-space: normal; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
}
.apie_libWrap mat-checkbox.mat-checkbox-checked.img_select label,
.apie_libWrap mat-radio-button.mat-radio-checked.img_select label {
    box-shadow: inset 0 0 0 1px #6e6e6e;
}

.apie_libWrap mat-checkbox.mat-checkbox-checked.img_select label .libSqImg,
.apie_libWrap mat-radio-button.mat-radio-checked.img_select label .libSqImg{
  transform: scale(0.8);
}
.mat-slide-toggle-thumb {
    height: 15px !important;
    width: 15px !important;
}
.mat-slide-toggle-thumb-container {
    width: 15px !important;
    height: 15px !important;
    top: -3px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(20px, 0, 0) !important;
}
.mat-slide-toggle-bar {
    height: 10px !important;
}
.auto-height .mat-radio-label {
    height: auto !important;
}
.profile-timeline {
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow: auto;
    padding-bottom: 15px;
}
.loader {
    display: block;
    line-height: normal;
    text-align: center;
    padding: 12px 16px;
}
.loader__circle {
    -webkit-animation-name: loader-animation--inverted;
    animation-name: loader-animation--inverted;
}
.loader__circle {
    border-radius: 50%;
    display: inline-block;
    height: 18px;
    margin: 0 3.6px;
    width: 18px;
    -webkit-animation-name: loader-animation;
    animation-name: loader-animation;
    -webkit-animation-duration: 0.8775s;
    animation-duration: 0.8775s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
}
.loader__circle--small {
    height: 10px;
    width: 10px;
}
.loader__circle:first-child {
    -webkit-animation-delay: 0.1795s;
    animation-delay: 0.1795s;
}
.loader__circle:nth-child(2) {
    -webkit-animation-delay: 0.4055s;
    animation-delay: 0.4055s;
}
.loader__circle:nth-child(3) {
    -webkit-animation-delay: 0.5285s;
    animation-delay: 0.5285s;
}
@-webkit-keyframes loader-animation {
    50% {
        background-color: #fff;
    }
}
@keyframes loader-animation {
    50% {
        background-color: #fff;
    }
}
.multifile_addzone button {
    padding: 35px;
}
.multifile_addzone button .mat-icon {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 30px;
}
.blurryImg {
    filter: blur(3px);
}
.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}
.fixedDualBtn {
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    color: #fff;
    width: 100%;
}
.half-widthBtn {
    width: 50%;
    float: left;
    width: 50%;
    float: left;
    height: 40px;
    background: #343a40;
    color: #fff;
    border-radius: 0 !important;
}
.addMore {
    background: #242525;
}
._firecmsSearch {
    position: relative;
}
._firecmsSearch input {
    border: 0;
    padding: 0 32px 0 18px;
    background: #f6f8f9;
    color: #273142;
    min-height: 32px;
    border-radius: 12px;
    font-size: 12px;
    width: 160px;
    transition: 0.3s all;
}
._firecmsSearch input.isFocused {
    width: 220px;
}
._firecmsSearch button {
    border-radius: 50% !important;
    color: #d23f11;
    background: rgb(247 223 215);
    font-size: 12px !important;
    margin-left: -30px !important;
    position: absolute;
    margin-top: -8px !important;
    border: 0;
    cursor: pointer !important;
    width: 24px;
    height: 24px;
    line-height: 24px !important;
    box-shadow: 1px 1px 6px 0 rgb(247 223 215);
}
._firecmsSearch button.searchIcon {
    background: #ff6d00;
    color: #fff;
}
.ace-editor {
    height: 250px;
}
.ace-editor ace-editor {
    height: calc(100% - 53px);
}
.ace-editor .name {
    color: #fff;
    font-size: 12px !important;
    font-weight: 400 !important;
    white-space: nowrap;
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.ace-editor .name i {
    margin-right: 2px;
    font-size: 12px;
}
.ace-editor .name.html i {
    color: #f4bf75;
}
.ace-editor .name.css i {
    color: #1e9cef;
}
.ace-editor .name.json i {
    color: #1e9cef;
}
.ace-editor .name.js i {
    color: #ecec2e;
}
.ace-editor .info {
    background: #252526;
}
.ace-editor .info.inFullScreen {
    border-radius: 0;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(25, 149, 55, 0.54);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: rgba(25, 149, 55);
}
.loading-pulse {
    position: fixed;
    left: 50%;
    top: 50%;
    bottom: 50%;
    right: 50%;
    width: 6px;
    height: 24px;
    background: #0f3f81;
    -webkit-animation: pulseLoader 750ms infinite;
    animation: pulseLoader 750ms infinite;
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
}
.loading-pulse:after,
.loading-pulse:before {
    content: "";
    position: absolute;
    display: block;
    height: 16px;
    width: 6px;
    background: #0f3f81;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: pulseLoader 750ms infinite;
    animation: pulseLoader 750ms infinite;
}
.loading-pulse:before {
    left: -12px;
}
.loading-pulse:after {
    left: 12px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
@-webkit-keyframes pulseLoader {
    50% {
        background: #fac40c;
    }
}
@keyframes pulseLoader {
    50% {
        background: #fac40c;
    }
}
.mat-icon {
    text-align: center;
    line-height: 24px;
}
.modalbodayWrap .card-body {
    padding-left: 0;
    padding-right: 0;
}
#profile .apie_content .info {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 6px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}
#profile .apie_content .info .apie_row-1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    box-sizing: border-box;
    display: -webkit-box;
    display: flex;
    place-content: center space-between;
    -webkit-box-align: center;
    align-items: center;
    height: auto;
}
#profile .apie_content .info .apie_row-1 .name {
    font-size: 16px;
    font-weight: 600;
}
#profile .apie_content .info .apie_row-1 .notImg .name {
    font-size: 12px;
    font-weight: 400;
}
#profile .apie_content .info .apie_row-1 .thumb-cover-img + .notImg .name {
    font-weight: 600;
    font-size: 14px;
}
.layout-select .mat-radio-container {
    opacity: 0;
    position: absolute;
}
.layout-select .layoutlist {
    display: inline-block;
    padding: 15px;
    border: 1px solid #f1f1f1;
    text-align: center;
    margin: 0;
    border-radius: 18px;
    overflow: hidden;
}
.layout-select .mat-radio-button.mat-radio-checked .layoutlist {
    border: 1px solid #000;
}
.layout-select .layoutName {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.layout-select .mat-radio-label-content {
    padding: 0;
    display: contents;
}
.apie_container_preview {
    width: 100%;
    min-height: 100px;
    border: 1px dashed #dee2e6;
    margin: 20px 0;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
}
.apie_row_preview {
    min-height: 80px;
    border: 1px dashed #dee2e6;
    margin: 10px 0;
    border-radius: 8px;
    padding: 6px;
}
.apie_col_preview {
    width: 60%;
    min-height: 40px;
    border: 1px dashed #dee2e6;
}
.apie_element_preview {
    border: 1px dashed #dee2e6;
    border-radius: 8px;
    padding: 6px;
    font-size: 10px;
    font-weight: 600;
}
.apie_element_preview + .apie_element_preview {
    margin-top: 5px;
}
.eleDeteteBtn {
    color: #d23f11;
    background: rgb(210 63 17 / 17%);
}
.eleLinkBtn {
    color: #4886ea;
    background: rgb(72 134 234 / 17%);
}
.apie_chips {
    list-style: none;
}
.apie_chips li {
    margin: 4px;
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 16px;
    align-items: center;
    cursor: pointer;
    min-height: 25px;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);
}
.cdk-overlay-container {
    position: fixed;
    z-index: 1051 !important;
}
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    padding-left: 15px !important;
}
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    padding-right: 15px !important;
}
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
    padding-left: 15px !important;
}
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
    padding-right: 15px !important;
}
td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
    border: 0;
}
.collection-table_view .mat-table-sticky {
    position: sticky;
}
.collection-table_view .mat-cell,
.collection-table_view .mat-footer-cell {
    font-size: 12px;
    font-weight: 400;
}
.collection-table_view .mat-header-row {
    background: #c5c5c5;
}
.collection-table_view.mat-table .mat-table-sticky,
.collection-table_view.mat-table thead {
    background: #c5c5c5;
}
.collection-table_view .mat-header-row .mat-header-cell {
    padding: 0 0.75em !important;
}
.collection-table_view .mat-row .mat-cell {
    padding: 0 !important;
    min-width: 170px;
}
.collection-table_view .mat-row .mat-cell .text-truncate {
    width: auto;
}
.collection-table_view .mat-footer-row,
.collection-table_view .mat-header-row,
.collection-table_view .mat-row {
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: rgb(0 0 0 / 6%);
    border-style: solid;
    box-sizing: border-box;
}
.collection-table_view .mat-cell {
    min-width: 0;
}
.collection-table_view .mat-cell,
.collection-table_view .mat-footer-cell,
.collection-table_view .mat-header-cell {
    overflow: hidden;
    word-wrap: break-word;
    min-height: inherit;
}
.collection-table_view .mat-footer-row,
.collection-table_view .mat-row {
    min-height: 48px;
}
.collection-table_view .mat-row {
    position: relative;
    cursor: pointer;
    height: auto;
}
.collection-table_view .mat-cell img {
    height: 35px;
}
.collection-table_view .mat-row:nth-child(even) {
    background-color: #f6f8f9;
}
.mat-grid-tile .mat-figure {
    flex-direction: column;
}
.apie_floating-toolbar {
    width: 40px;
    position: fixed;
    bottom: 0;
    z-index: 0;
    top: 0;
    background: #2b2a2a;
    border-radius: 0 33px 33px 0;
    height: 100vh;
    /* -webkit-transform: translate3d(-75px, 0, 0);
    transform: translate3d(-75px, 0, 0); */
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    z-index: 1;
    transform: translateX(-100%);
}

.apie_floating-toolbar:after,
.apie_floating-toolbar:before{
    content: "";
    width: 20px;
    height: 20px;
    left: -20px;
    position: absolute;
    background: #2b2a2a;
}

.apie_floating-toolbar:after{
    bottom: 0;
}

.apie_floating-toolbar:hover{
    transform: translateX(-34px);
}

.mat-progress-bar{
    position: absolute !important;
}

.apie_navbar {
    border: 0;
    margin: 0;
    transition: border-radius ease 0.5s;
}
.apie_floating-toolbar .apie_navbar {
    height: 100%;
    flex-flow: column nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-collapse {
    flex-flow: column wrap;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav {
    -ms-flex-direction: column;
    flex-direction: column;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item a {
    padding: 0 5px !important;
    font-size: 14px;
    color: #4caf50;
    background: #0d0d0d;
    border-radius: 12px;
    text-align: center;
    width: 34px;
    height: 34px;
    line-height: 34px;
    opacity: 0;
    -webkit-transform: translate3d(-75px, 0, 0);
    transform: translate3d(-75px, 0, 0);
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.4s, transform 0.4s;
}
.apie_floating-toolbar.revealed .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item a {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.apie_floating-toolbar.revealed .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(2) a {
    -webkit-transition-delay: 50ms;
    transition-delay: 50ms;
}
.apie_floating-toolbar.revealed .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(3) a {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}
.apie_floating-toolbar.revealed .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(4) a {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}
.apie_floating-toolbar.revealed .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(5) a {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(2) a {
    color: #ff6d00;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(3) a {
    color: #d23f11;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(4) a {
    color: #673ab7;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item:nth-child(5) a {
    color: #ffc107;
}
.apie_floating-toolbar .apie_navbar-expand-lg .apie_navbar-nav .apie_nav-item + .apie_nav-item a {
    margin-top: 15px !important;
}
.apie_floating-toolbar .apie_bg-dark {
    background-color: #2b2a2a !important;
}
.apie_floating-toolbar.revealed .apie_navbar {
    border-radius: 0 33px 33px 0 !important;
    box-shadow: 1px 0 16px 3px rgb(0 0 0 / 13%);
}
.apie_panel-trigger {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    right: -15px;
    border-radius: 0px 12px 0px 0px;
    background: #2b2a2a;
    /* padding: 4px; */
    text-align: center;
}

.apie_panel-trigger a{
    transform: rotate(-45deg);
    position: relative;
    cursor: pointer;
}

.apie_panel-trigger a img{
    height: 40px;
}

.apie_panel-trigger a:after,
.apie_panel-trigger a:before {
    content: "";
    width: 14px;
    height: 50px;
    left: 25px;
    position: absolute;
    background: transparent;
}
.apie_panel-trigger a:before {
    top: -46px;
    /* background: url(../apie_img/top-curve.png) no-repeat; */
    background-size: contain;
    background-position: bottom;
    transform: skewY(45deg);
    border-radius: 0px 0 0 55px;
    box-shadow: -4px 3px 0px 3px #2b2a2a;
}
.apie_panel-trigger a:after {
    bottom: -46px;
    /* background: url(../apie_img/bottom-curve.png) no-repeat; */
    background-size: contain;
    background-position: top;
    transform: skewY( -45deg);
    border-radius: 55px 0 0 0;
    box-shadow: -4px -3px 0px 3px #2b2a2a;
}
.apie_floating-toolbar.revealed {
    /* -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); */
}
#clonedToolbar.apie_floating-toolbar.revealed {
    position: relative;
    height: calc(100vh - 30px);
    transform: none;
}
#clonedToolbar.apie_floating-toolbar .apie_navbar {
    /* padding-left: 25px; */
    box-shadow: none;
}
#clonedToolbar.apie_floating-toolbar {
    min-width: 40px;
}
.apie_contentwrap {
    padding: 15px;
}
.modalbodayWrap > .apie_contentwrap {
    padding-top: 0;
    padding-bottom: 0;
}
.modalbodayWrap > .apie_contentwrap > .apie_row {
    height: calc(100vh - 30px);
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 {
    background: #fff;
    border-radius: 25px 25px 25px 25px;
    overflow: hidden;
    box-shadow: -20px 0 15px -5px rgb(0 0 0 / 13%);
    overflow: auto;
    height: calc(100vh - 80px);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: fixed;
    max-width: 18.666667%;
    right: 10px;
    top: 70px;
    z-index: 999;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5.sidebarHover {
    position: fixed;
    right: 10px;
    max-width: 30%;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-12 > .scrollable,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 > .scrollable {
    height: calc(100vh - 225px);
    overflow-y: scroll;
    position: relative;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-12 .block,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 .block,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .block {
    border: 0;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-12 .block .info,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 .block .info,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .block .info {
    background: 0 0;
    line-height: 65px;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-12 .block .info .name,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 .block .info .name,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .block .info .name {
    font-size: 14px;
    color: #273142;
    margin-bottom: 0 !important;
    font-weight: 600;
    font-family: Montserrat !important;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .hide_radio_check .mat-radio-label {
    background: #f8f8f8;
    margin-bottom: 15px;
    height: auto;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .hide_radio_check .mat-radio-checked .mat-radio-label,
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .hide_radio_check .mat-radio-label:hover {
    background: #c7c7c7;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .hide_radio_check p {
    color: #273142;
    font-family: Montserrat !important;
    font-size: 10px;
    font-weight: 500;
    padding: 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.modalbodayWrap > .apie_contentwrap > .apie_row > .apie_col-md-6 + .apie_col-md-5 .hide_radio_check i {
    line-height: 40px;
    font-size: 18px;
    color: #273142;
    margin-bottom: 5px;
}
.actionBtn {
    min-height: 32px;
    display: inline-block !important;
    border-radius: 12px !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
}
.actionBtn[disabled] {
    cursor: not-allowed !important;
}
.actionBtn:not([disabled]) {
    color: #4caf50 !important;
    background: rgb(76 175 80 / 23%) !important;
}
.apie_grid-items {
    cursor: pointer;
    min-height: 140px;
    background: #f6f8f9;
    margin-bottom: 15px;
    border-radius: 18px;
    padding: 10px;
    color: #273142;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}
.apie_grid-items h2 {
    color: #273142;
    font-family: Montserrat !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.apie_modal-header .action {
    font-size: 14px;
    color: #273142;
}
.apie_grid-items .type {
    color: #4e4e4e;
    font-weight: 500;
    font-size: 10px;
    font-family: Montserrat !important;
}
.apie_grid-items > .lftContnt > button {
    position: relative;
    top: -10px;
    left: -10px;
    background: #bcabdb;
    border: 2px solid #fff;
    color: #fff;
}
.apie_grid-items > .lftContnt > button ion-icon {
    font-size: 34px;
}
.apie_grid-items .actions > button {
    width: 30px;
    height: 30px;
    font-size: 16px;
    line-height: 10px !important;
    overflow: hidden;
}
.apie_grid-items .actions > button:first-child {
    color: #4caf50;
    background: rgb(76 175 80 / 17%);
}
.apie_grid-items .actions > button:nth-child(2) {
    color: #ff6d00;
    background: rgb(255 109 0 / 17%);
}
.apie_grid-items .actions > button:last-child {
    color: #d23f11;
    background: rgb(210 63 17 / 17%);
}
.apie_primaryBtnclr {
    padding: 0 32px 0 18px !important;
    color: #4caf50 !important;
    background: rgb(76 175 80 / 17%) !important;
    min-height: 32px;
    font-weight: 400 !important;
    border-radius: 12px !important;
    font-size: 12px;
    line-height: 32px !important;
}
.apie_BtnWithIcn i,
.apie_BtnWithIcn mat-icon {
    background: #4caf50;
    color: #fff;
    font-size: 12px !important;
    position: absolute;
    margin-top: -10px !important;
    right: 5px;
    border: 0;
    cursor: pointer !important;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 24px !important;
}
.min-h-auto {
    min-height: auto !important;
}
.apie_side-panel-overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
}
.apie_grid-items.user-verified {
    color: #4caf50;
    background: rgb(76 175 80 / 17%);
}
.apie_grid-items.user-verified .username {
    font-weight: 500;
}
.apie_grid-items.user-verified .type {
    color: #4caf50 !important;
}
.apie_grid-items.user-cancelled {
    color: #d23f11;
    background: rgb(210 63 17 / 17%);
}
.apie_grid-items.user-cancelled .username {
    font-weight: 500;
}
.apie_grid-items.user-cancelled .type {
    color: #d23f11 !important;
}
.apie_step {
    width: 100%;
    min-height: 100px;
    border-style: solid;
}
.apie_field {
    width: 50%;
    min-height: 50px;
    border-style: dotted;
}
.example-tree-invisible {
    display: none;
}
.mat-tree {
    background: 0 0 !important;
}
.mat-tree-node {
    overflow: visible !important;
}
.apie_grid-items .mat-tree-node .actions > button + button {
    margin-left: 15px;
}
.apie_componentView {
    position: relative;
    z-index: 999;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(223,228,235,1) 0%, rgba(237,241,244,1) 100%);
    border-radius: 25px;
    box-shadow: 1px 0 16px 3px rgb(0 0 0 / 13%);
    border: 5px solid rgb(43 42 42 / 50%);
}
.apie_contentwrap .mat-form-field-underline {
    display: none !important;
}
.apie_contentwrap .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 18px;
    padding: 0.55em 0.75em 0 0.75em;
}
.apie_contentwrap .collection-table_view .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0;
    border-radius: 0;
}
.apie_contentwrap mat-form-field {
    font-size: 12px;
}
.customInput {
    padding: 0.75em !important;
    background: #f6f8f9;
    line-height: 3 !important;
    border: 1px solid transparent !important;
    width: 100%;
}
.customInput:focus {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
}
.collection-table_view .mat-row .mat-form-field-wrapper {
    padding-bottom: 0;
}
.collection-table_view .mat-row:nth-child(even) .customInput {
    background: #fff;
}
.collection-table_view .mat-row:nth-child(even) .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0;
}
.collection-table_view .mat-row .mat-form-field-appearance-fill .mat-form-field-infix {
    border-top: 0;
    padding: 0;
}
.lftContnt h2 {
    word-break: break-all;
}
.delete-wrapper-outer {
    min-height: calc(100vh - 145px);
}
.delete-wrapper {
    /* min-height: 50vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.delete-wrapper ._gtRn i {
    color: #f44336;
}
.delete-wrapper ._gtRn h2 {
    font-family: Montserrat !important;
    margin-top: 30px;
    font-weight: 700;
    font-size: 2vw;
}
.delete-wrapper ._gtRn p {
    font-family: Montserrat !important;
    font-weight: 500;
}
.delete-wrapper .ulPly p {
    font-family: Montserrat !important;
}
.deleteBtn {
    min-height: 46px;
    border-radius: 25px !important;
    text-transform: uppercase !important;
    font-family: Montserrat !important;
    font-weight: 400 !important;
    letter-spacing: 1px;
    width: 220px;
}
.deleteBtn[disabled] {
    cursor: not-allowed !important;
}
.deleteBtn:not([disabled]) {
    color: #fff !important;
    background: #f44336 !important;
}
input[type="checkbox"].normalCheck {
    height: auto !important;
    position: relative !important;
    width: auto !important;
}
.matching {
    color: green;
}
.notMatching {
    color: red;
}
.apie_setting-section {
    padding: 15px;
}
.apie_setting-section .apie_row [class^="apie_col-"] {
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 30px;
    transition: transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93), -webkit-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93), -o-transform 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    background: #fff;
    min-height: 250px;
    cursor: pointer;
}
.apie_setting-section .apie_row [class^="apie_col-"]:hover {
    -webkit-box-shadow: 0 5px 50px rgb(0 0 0 / 5%);
    box-shadow: 0 5px 50px rgb(0 0 0 / 5%);
    z-index: 10;
}
.apie_setting-section .apie_row [class^="apie_col-"] .in-grid .gridLabel span {
    font-size: 24px;
    font-weight: 600;
}
.apie_setting-section .apie_row [class^="apie_col-"] .in-grid p {
    color: #67757c;
    font-size: 12px;
}
@media screen and (device-aspect-ratio: 2/3) {
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px !important;
    }
}
@media screen and (device-aspect-ratio: 40/71) {
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px !important;
    }
}
@media screen and (device-aspect-ratio: 375/667) {
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px !important;
    }
}
@media screen and (device-aspect-ratio: 9/16) {
    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 16px !important;
    }
}

.selected-chip {
    background-color: green !important;
    color: #fff;
}
.apie_mh-auto {
    min-height: auto !important;
}
.editor-resizer {
    position: relative;
    z-index: 2;
    width: 18px;
    cursor: col-resize;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    background: #333642;
}
split-view {
    height: auto;
}
.ace-editor.xyz {
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: 0;
    width: 90%;
    height: 90%;
    z-index: 999;
    border-radius: 15px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    transition: 0.5s all;
}
@media (min-width: 768px) {
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 3),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 4) {
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(2n + 1) {
        transform: scale3d(1.1, 1.1, 1) translate3d(20px, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row .apie_col-md-6:hover:nth-child(2n + 2) {
        transform: scale3d(1.1, 1.1, 1) translate3d(-20px, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover {
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(0);
    }
}
@media (min-width: 992px) {
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(2n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(2n + 2),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 4) {
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 1) {
        transform: scale3d(1.1, 1.1, 1) translate3d(20px, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 3) {
        transform: scale3d(1.1, 1.1, 1) translate3d(-20px, 0, 0) perspective(0);
    }
}
@media (min-width: 1200px) {
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(2n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(2n + 2),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 1),
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(3n + 3) {
        transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 1) {
        transform: scale3d(1.1, 1.1, 1) translate3d(20px, 0, 0) perspective(0);
    }
    .apie_setting-section .apie_row [class^="apie_col-"]:hover:nth-child(4n + 4) {
        transform: scale3d(1.1, 1.1, 1) translate3d(-20px, 0, 0) perspective(0);
    }
}
.angular-editor-toolbar .angular-editor-toolbar-set {
    margin-right: 0 !important;
}
.angular-editor-toolbar .angular-editor-toolbar-set button {
    margin-right: 6px;
    border: 0 !important;
    background: #3a3a3a !important;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 13%);
}
.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button:disabled,
.ae-font .ae-picker-label:disabled {
    opacity: 0.3;
}
.angular-editor-toolbar .angular-editor-toolbar-set button i.fa {
    font-weight: 100 !important;
}
.ae-font .ae-picker-label:before {
    background: #464646 !important;
}
.ae-font .ae-picker-label svg .ae-stroke {
    stroke: #fff !important;
}
.angular-editor-toolbar {
    background-color: #2b2a2a  !important;
    padding: 15px !important;
    border: 0 !important;
    border-radius: 20px 20px 0 0; 
    box-shadow: 0px 0px 74px 11px rgb(115 131 169 / 23%);
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-custom-style,
.angular-editor-toolbar .angular-editor-toolbar-set .select-heading {
    margin-right: 6px;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font {
    width: auto !important;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font {
    margin-right: 6px;
}
.angular-editor-toolbar .angular-editor-toolbar-set .select-font:hover {
    background-color: transparent !important;
}
.ae-font .ae-picker-label {
    margin-right: 18px !important;
}
.modal-open .modal.custom-modal {
    backdrop-filter: blur(6px);
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea{
    min-height: 20rem !important;
    border: 0 !important;
    margin-top: 0;
    max-height: 20rem !important;
    font-family: 'Roboto';
}

.angular-editor-toolbar .angular-editor-toolbar-set .select-heading:hover,
.angular-editor-toolbar .angular-editor-toolbar-set .select-custom-style:hover {
    background-color: transparent !important;
}

.ae-font .ae-picker-options{
    border: 0 !important;
    background-color: transparent !important;
}

.ae-font .ae-picker-options .ae-picker-item{
    border-radius: 0 !important;
}

.ae-font .ae-picker-options .ae-picker-item:last-child{
    border-radius: 0 0 5px 5px !important;
}
.ae-font.ae-expanded > button{
    border-bottom-left-radius:0 !important ;
    border-bottom-right-radius: 0 !important;
}

.angular-editor .angular-editor-wrapper{
    background: #fff;
    box-shadow: 0px 0px 74px 11px rgb(115 131 169 / 23%);
    border-radius: 0 0 20px 20px;
}

.btnGreenBg{
    background: #4caf50;
      color: #fff;
      transform: scale(0.8);
  }

  ngb-modal-window .component-host-scrollable{
      overflow: visible !important;
  }